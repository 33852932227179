@mixin widget {
  display: flex;
  flex-direction: column;
  width: 432px;
  border: 1px solid #E6E7E8;
  padding-top: 24px;
  padding-left: 32px;
}

.widget {
  @include widget;
}

.widget-title {
  font-size: 25px;
  color: #223250;
  margin-bottom: 32px;
}

.settings-row {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.left-column {
  font-weight: bold;
}

.left-value {
  margin-left: 10px;
}